import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import DaysFilter from "./DaysFilter";
import ErrorPage from "../../common-components/error";
import moment from "moment";
import subDays from "date-fns/subDays";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CommonLoader from "../../../../loader/CommonLoader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UserStats = () => {
  const [selectedDays, setSelectedDays] = useState([
    subDays(new Date(), 30 - 1),
    new Date(),
    // "2024-08-01",
    // "2024-09-01-",
  ]);
  const [counts, setCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);

    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");

    try {
      const response = await axiosInstance.post(
        "/api/dashboard",
        { days: null, from, to },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCounts(response.data.counts);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedDays]);

  const data = {
    labels: [
      "Super Admins",
      "Admins",
      "Users",
      "Clients",
      "Freelancers",
      "Account Managers",
      "TikTok Accounts",
    ],
    datasets: [
      {
        label: "Counts",
        data: [
          counts.super_admins || 0,
          counts.admins || 0,
          counts.users || 0,
          counts.clients || 0,
          counts.freelancers || 0,
          counts.account_managers || 0,
          counts.tiktok_accounts || 0,
        ],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
        ],
      },
    ],
  };
  // const attachTooltip = () => {
  //   return `<>
  //       <span className="help-tooltip" data-tooltip-id="noedit-button">
  //         Total Users and Tiktok Accounts + <IoIosHelpCircleOutline />
  //       </span>
  //       <ReactTooltip id="help-tooltip" place="bottom" content="Help" />
  //     </>`;
  // };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 29), new Date()]);
    else setSelectedDays(dates);
  };

  return (
    <div className="col-md-12">
      <DaysFilter selectedDays={selectedDays} onChange={onChange} />
      {loading ? (
        <CommonLoader />
      ) : error ? (
        <ErrorPage refetch={fetchData} />
      ) : (
        <div className="chart-wrapper">
          {Object.keys(counts) ? (
            <>
              <h5 className="chart-title">
                Total Users and TikTok Accounts &nbsp;
                <span
                  data-tooltip-id="help-tooltip"
                  style={{ fontSize: "26px" }}
                >
                  <IoIosHelpCircleOutline />
                </span>
                <ReactTooltip
                  id="help-tooltip"
                  place="bottom"
                  content="Helping Text"
                />
              </h5>
              <Bar data={data} options={options} />
            </>
          ) : (
            <p>No data available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default UserStats;
