import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import DaysFilter from "./DaysFilter";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import subDays from "date-fns/subDays";
import SkeletonLoader from "../../common-components/skeletonLoader";
import ErrorPage from "../../common-components/error";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TikTokStats = () => {
  const [selectedDays, setSelectedDays] = useState([
    subDays(new Date(), 30 - 1),
    new Date(),
  ]);
  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTikTokStats = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");
    try {
      const response = await axiosInstance.post(
        "/api/dashboard/client-tiktok-stats",
        { days: null, from, to },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClientData(response.data.data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch TikTok stats data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTikTokStats();
  }, [selectedDays]);

  const generateChartData = (client) => {
    const labels = client.tiktok_accounts.map(
      (account) => account.tiktok_nickname
    );
    const data = {
      labels,
      datasets: [
        {
          label: "Followers",
          data: client.tiktok_accounts.map((account) => account.follower_count),
          backgroundColor: "#36A2EB",
        },
        {
          label: "Following",
          data: client.tiktok_accounts.map(
            (account) => account.following_count
          ),
          backgroundColor: "#FFCE56",
        },
        {
          label: "Total Likes",
          data: client.tiktok_accounts.map((account) => account.total_likes),
          backgroundColor: "#4BC0C0",
        },
        {
          label: "Total Videos",
          data: client.tiktok_accounts.map((account) => account.total_videos),
          backgroundColor: "#FF6384",
        },
      ],
    };
    return data;
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  if (error) return <ErrorPage refetch={fetchTikTokStats} />;

  return (
    <div className="row">
      <div className="col-md-12">
        <DaysFilter selectedDays={selectedDays} onChange={onChange} />

        {loading && (
          <div className="loader-overlay">
            <CommonLoader />
          </div>
        )}

        {!loading && !error && clientData.length > 0
          ? clientData.map((client) => (
              <div key={client.client_id} className="client-chart">
                {client.tiktok_accounts.length > 0 ? (
                  <>
                    <h5 className="chart-title">
                      TikTok Stats for ${client.client_name} &nbsp;
                      <span
                        data-tooltip-id="help-tooltip"
                        style={{ fontSize: "26px" }}
                      >
                        <IoIosHelpCircleOutline />
                      </span>
                      <ReactTooltip
                        id="help-tooltip"
                        place="bottom"
                        content="Helping Text"
                      />
                    </h5>
                    <Bar
                      data={generateChartData(client)}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: { position: "top" },
                          title: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </>
                ) : (
                  <p>No TikTok accounts available for this client.</p>
                )}
              </div>
            ))
          : !loading && !error && <p>No TikTok stats available.</p>}
      </div>
    </div>
  );
};

export default TikTokStats;
