import React, { useEffect, useState } from "react";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import TikTokPostFilter from "./component/TikTokPostFilter";
import { FaLink } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Badge from "react-bootstrap/Badge";

const TikTokVideosIndex = () => {
  const [videos, setVideos] = useState([]);
  const [tiktokAccounts, setTikTokAccounts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageStart, setPageStart] = useState(1);
  const [filters, setFilters] = useState({
    tiktok_id: null,
    views_from: "",
    views_to: "",
    likes_from: "",
    likes_to: "",
    comments_from: "",
    comments_to: "",
    shares_from: "",
    shares_to: "",
    saved_from: "",
    saved_to: "",
    tags: [],
    from: "",
    to: "",
  });
  const [activeTagsList, setActiveTagsList] = useState([]);
  const [record, setRecord] = useState({
    visible: false,
    tag: [],
  });

  const fetchTikTokVideos = async (page = 1) => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.post(
        `/api/tiktok/user/videos?page=${page}`,
        filters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const videoData = response.data.data;
      setVideos(videoData);
      const { pagination: apiPagination } = response.data;
      setPagination(apiPagination);
      setCurrentPage(apiPagination.current_page);
      setTotalPages(apiPagination.last_page);
      setTikTokAccounts(response.data.tiktok_users);
      setLoading(false);
    } catch (err) {
      setVideos([]);
      setError("Failed to fetch TikTok Videos");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTikTokVideos(currentPage);
  }, [currentPage, filters]);

  useEffect(() => {
    getActiveTags();
  }, []);

  const getActiveTags = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(`/api/active-tags`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const localActiveTags = response.data.tags?.map((tag) => ({
          label: tag.title,
          value: tag.id,
        }));
        setRecord({
          ...record,
          visible: true,
        });
        setActiveTagsList(localActiveTags);
      }
    } catch (error) {
      console.error("Error fetching record for edit:", error);
    }
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
    fetchTikTokVideos(1);
  };

  const getActiveBadges = (video) => {
    return video?.tag?.map((t, i) => (
      <Badge
        bg="success"
        key={i}
        style={{ display: "block", marginBottom: "5px" }}
      >
        {t.title}
      </Badge>
    ));
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader dashboardName={"TikTok Posts"} />
      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">
            <div
              style={{ backgroundColor: "#fff" }}
              className="d-flex align-content-center align-items-center"
            >
              <TikTokPostFilter
                tiktokUsers={tiktokAccounts}
                activeTagsList={activeTagsList}
                onFilterChange={handleFilterChange}
              />
            </div>
            <div className="details mt-4">
              <table className="user-table">
                <thead>
                  <tr>
                    <th>Account Name</th>
                    <th>Tiktok Username</th>
                    <th>Video ID</th>
                    <th>Video Link</th>
                    <th>Video Creation Time</th>
                    <th>Sound ID</th>
                    <th>Views</th>
                    <th>Likes</th>
                    <th>Comments</th>
                    <th>Shares</th>
                    <th>Saved</th>
                    <th>Tags</th>
                  </tr>
                </thead>
                <tbody>
                  {videos.length > 0 ? (
                    videos.map((video, index) => (
                      <tr key={index}>
                        <td>{video.client}</td>
                        <td>{video.tiktok_nickname}</td>
                        <td>
                          <Link
                            to={`/Tiktok-video-statistics/${video?.video_id}`}
                          >
                            <span data-tooltip-id="noedit-button">
                              <FaExternalLinkAlt />
                            </span>
                            <ReactTooltip
                              id="noedit-button"
                              place="bottom"
                              content="go to video statistics"
                            />
                          </Link>
                        </td>
                        <td>
                          <a
                            href={video.video_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaLink />
                          </a>
                        </td>
                        <td>{video.create_time}</td>
                        <td>
                          <Link
                            to={`/Tiktok-sound-statistics/${video?.sound_id}`}
                          >
                            {video?.sound_id}
                          </Link>
                        </td>
                        <td>{video.views}</td>
                        <td>{video.likes}</td>
                        <td>{video.comments}</td>
                        <td>{video.shares}</td>
                        <td>{video.saved}</td>
                        <td>{getActiveBadges(video)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10">No videos found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {videos.length > 0 && totalPages > 1 && (
              <div className="pagination">
                <PaginationComponent
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  pageStart={pageStart}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TikTokVideosIndex;