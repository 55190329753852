import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import { Toaster, toast } from "sonner";
import { useNavigate } from "react-router-dom";
import CommonLoader from "../../../../loader/CommonLoader";
import { returnBackButton } from "../../../../utils/helperfunctions";
import { LoginContext } from "../../../../App";

const CheckboxPermission = ({ permission, onCheckboxChange }) => {
  return (
    <tr style={permission.style}>
      <td style={{ padding: "8px" }}>{permission.name}</td>
      <td style={{ padding: "8px" }}>
        <input
          type="checkbox"
          className="checkbox"
          checked={permission.checked}
          onChange={() => onCheckboxChange(permission.id)}
        />
      </td>
    </tr>
  );
};

const ModelRoleListingPermission = ({ userId }) => {
  const [permissions, setPermissions] = useState([]),
    [initialCheckedIds, setInitialCheckedIds] = useState([]),
    [checkedIds, setCheckedIds] = useState([]),
    navigate = useNavigate(),
    [isLoading, setIsLoading] = useState(false),
    { setUser } = useContext(LoginContext);

  useEffect(() => {
    fetchPermissions();
    const intervalId = setInterval(() => {
      fetchPermissions();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [userId]);

  const token = localStorage.getItem("token");

  const fetchPermissions = async (update = false) => {
    if (userId) {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `/api/roles/${userId}/give-permissions`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const permissionsWithCheck = response.data.permissions.map(
          (permission, index) => ({
            ...permission,
            checked: response.data.rolePermissions.hasOwnProperty(
              permission.id
            ),
            style: index % 2 === 0 ? { backgroundColor: "#f9f9f9" } : {},
          })
        );
        setIsLoading(false);
        setPermissions(permissionsWithCheck);
        const hasPermission = permissionsWithCheck.filter(
          (permission) => permission.checked
        );

        if (update) {
          const updatedPermissions = hasPermission.map(
            (permission) => permission.name
          );

          // update localstorage
          localStorage.setItem(
            "permissions",
            JSON.stringify(updatedPermissions)
          );

          // update state in context tree
          setUser((prevState) => ({
            ...prevState,
            permissions: updatedPermissions,
          }));
        }

        const checkedIds = hasPermission.map((permission) => permission.id);
        setInitialCheckedIds(checkedIds);
        setCheckedIds(checkedIds);
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.status === 401) {
          navigate("/");
          window.location.reload();
        } else {
          if (error.response.status === 404) {
            navigate("/");
            window.location.reload();
            alert("permission for this user is not found ");
          }
          console.error("Error fetching permissions", error);
        }
      }
    } else {
      navigate("/");
    }
  };

  const handleCheckboxChange = (permissionId) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((permission) =>
        permission.id === permissionId
          ? { ...permission, checked: !permission.checked }
          : permission
      )
    );
    setCheckedIds((prevIds) => {
      if (prevIds.includes(permissionId)) {
        return prevIds.filter((id) => id !== permissionId); // Uncheck
      } else {
        return [...prevIds, permissionId]; // Check
      }
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const permissionData = {
        permission: checkedIds,
      };

      await axiosInstance.put(
        `/api/roles/${userId}/give-permissions`,
        permissionData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setIsLoading(false);
      toast.success("Permissions updated successfully");

      fetchPermissions(true);
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting permissions", error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="userLists">
      <div className="mt-1">
        <div className="userFetchData">
          <div className="userDetails mt-2">
            <div
              style={{ backgroundColor: "#fff" }}
              className="d-flex rounded-2 align-items-center"
            >
              {returnBackButton()}
              <h1 className="fw-bold m-0 p-3 text-center rounded">
                Permissions by Role
              </h1>
            </div>
            <div className="videoDetails mt-3">
              <table
                style={{
                  maxWidth: "80%",
                  borderCollapse: "collapse",
                  margin: "auto",
                }}
              >
                <thead>
                  <tr>
                    <th
                      className="text-center fs-4"
                      style={{ padding: "8px", textAlign: "left" }}
                    >
                      Permission
                    </th>
                    <th
                      className="text-center fs-4"
                      style={{ padding: "8px", textAlign: "left" }}
                    >
                      Allow
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center fs-5 ">
                  {permissions.map((permission) => (
                    <CheckboxPermission
                      key={permission.id}
                      permission={permission}
                      onCheckboxChange={handleCheckboxChange}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <Toaster richColors position="top-center" />
        <button
          onClick={handleSubmit}
          style={{
            marginTop: "16px",
            padding: "8px 16px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Update Permission
        </button>
      </div>
      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
    </div>
  );
};

export default ModelRoleListingPermission;
