import React, { useEffect, useState, useContext } from "react";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import DaysFilter from "./component/DaysFilter";
import moment from "moment";
import subDays from "date-fns/subDays";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ImQuestion } from "react-icons/im";

const TikTokSoundsListing = () => {
    const [sounds, setSounds] = useState([]);
    const [selectedDays, setSelectedDays] = useState([
        subDays(new Date(), 30 - 1),
        new Date(),
    ]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pagination, setPagination] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageStart, setPageStart] = useState(1);

    const fetchSound = async (page = 1) => {
        setIsLoading(true);
        const from = moment(selectedDays[0]).format("YYYY-MM-DD");
        const to = moment(selectedDays[1]).format("YYYY-MM-DD");
        const token = localStorage.getItem("token");
        try {
            const response = await axiosInstance.post(`/api/tiktok/sounds?page=${page}`, {
                days: null,
                from: from,
                to: to
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setSounds(response.data.data);
            const { pagination: apiPagination } = response.data;
            setPagination(apiPagination);
            setCurrentPage(apiPagination.current_page);
            setTotalPages(apiPagination.last_page);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSound(currentPage);
    }, [currentPage, selectedDays]);

    const onChange = (dates) => {
        if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
        else setSelectedDays(dates);
    };

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page);
        const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
        setPageStart(newPageStart);
    };

    return (
        <div className="clientLists userLists">
            <CommonHeader
                dashboardName={"TikTok Sounds"}
            />
            <DaysFilter selectedDays={selectedDays} onChange={onChange} />
            {isLoading && (
                <div className="loader-overlay">
                    <CommonLoader />
                </div>
            )}
            <div className="mt-3">
                <div className="userFetchData">
                    <div className="userDetails">
                        <div className="details mt-4">
                            <table className="user-table">
                                <thead>
                                    <tr>
                                        <th>Sound ID</th>
                                        <th>Sound URL</th>
                                        <th data-tooltip-id="day1" data-tooltip-content={"Represents the Sum User Used the sound for the last 1 Day."}>Day 1 <ImQuestion /></th>
                                        <th data-tooltip-id="day2" data-tooltip-content={"Represents the Sum User Used the sound for the last 2 Days."}>Day 2 <ImQuestion /></th>
                                        <th data-tooltip-id="day3" data-tooltip-content={"Represents the Sum User Used the sound for the last 3 Days."}>Day 3 <ImQuestion /></th>
                                        <th data-tooltip-id="day4" data-tooltip-content={"Represents the Sum User Used the sound for the last 4 Days."}>Day 4 <ImQuestion /></th>
                                        <th data-tooltip-id="day5" data-tooltip-content={"Represents the Sum User Used the sound for the last 5 Days."}>Day 5 <ImQuestion /></th>
                                        <th data-tooltip-id="day6" data-tooltip-content={"Represents the Sum User Used the sound for the last 6 Days."}>Day 6 <ImQuestion /></th>
                                        <th data-tooltip-id="day7" data-tooltip-content={"Represents the Sum User Used the sound for the last 7 Days."}>Day 7 <ImQuestion /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sounds.map((sound, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Link to={`/Tiktok-sound-statistics/${sound?.sound_id}`}>
                                                    {sound.sound_id}
                                                </Link>
                                            </td>
                                            <td>
                                                <a href={sound.share_url} target="_blank" rel="noopener noreferrer">
                                                    View TikTok Sound
                                                </a>
                                            </td>
                                            <td>{sound.day1 ?? "0"}</td>
                                            <td>{sound.day2 ?? "0"}</td>
                                            <td>{sound.day3 ?? "0"}</td>
                                            <td>{sound.day4 ?? "0"}</td>
                                            <td>{sound.day5 ?? "0"}</td>
                                            <td>{sound.day6 ?? "0"}</td>
                                            <td>{sound.day7 ?? "0"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <ReactTooltip id="day1" place="top" type="dark" effect="solid" />
                            <ReactTooltip id="day2" place="top" type="dark" effect="solid" />
                            <ReactTooltip id="day3" place="top" type="dark" effect="solid" />
                            <ReactTooltip id="day4" place="top" type="dark" effect="solid" />
                            <ReactTooltip id="day5" place="top" type="dark" effect="solid" />
                            <ReactTooltip id="day6" place="top" type="dark" effect="solid" />
                            <ReactTooltip id="day7" place="top" type="dark" effect="solid" />
                        </div>
                        {totalPages > 1 && (
                            <div className="pagination">
                                <PaginationComponent
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    handlePageChange={handlePageChange}
                                    pageStart={pageStart}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TikTokSoundsListing;