import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { useParams } from "react-router-dom";
import DaysFilter from "./component/DaysFilter";
import moment from "moment";
import subDays from "date-fns/subDays";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import MetrixCards from "./userListingMetrix/MetrixCards";
import { SlUserFollowing } from "react-icons/sl";
import { GiShadowFollower } from "react-icons/gi";
import { MdFavorite } from "react-icons/md";
import { BiSolidVideos } from "react-icons/bi";
import { returnBackButton } from "../../../utils/helperfunctions";
import DaysFilterOLD from "../common-components/DateFilterOld";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TikTokUserStatistics = () => {
  const { tiktokId } = useParams();
  const [selectedDays, setSelectedDays] = useState([
    subDays(new Date(), 30 - 1),
    new Date(),
  ]);
  const [userStatistics, setUserStatistics] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserStatistics = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      const from = moment(selectedDays[0]).format("YYYY-MM-DD");
      const to = moment(selectedDays[1]).format("YYYY-MM-DD");
      try {
        const response = await axiosInstance.post(
          "/api/tiktok/tiktok-statistics",
          {
            tiktok_id: tiktokId,
            days: null,
            from,
            to,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserStatistics(response.data.statistics);
        setUserDetails(response.data.user_details);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch TikTok user statistics");
        setLoading(false);
      }
    };

    fetchUserStatistics();
  }, [tiktokId, selectedDays]);

  const generateChartData = () => {
    const labels = userStatistics.map((stat) =>
      new Date(stat.recorded_at).toLocaleDateString()
    );
    const data = {
      labels,
      datasets: [
        {
          label: "Visible Videos Count",
          data: userStatistics.map((stat) => stat.visible_videos_count),
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "#FF6384",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
          yAxisID: "y",
        },
        {
          label: "Followers",
          data: userStatistics.map((stat) => stat.follower_count),
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: "#36A2EB",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
          yAxisID: "y1",
        },
        {
          label: "Following",
          data: userStatistics.map((stat) => stat.following_count),
          backgroundColor: "rgba(255, 206, 86, 0.2)",
          borderColor: "#FFCE56",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
          yAxisID: "y1",
        },
        {
          label: "Total Favorited",
          data: userStatistics.map((stat) => stat.total_favorited),
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "#4BC0C0",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
          yAxisID: "y1",
        },
      ],
    };
    return data;
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: true,
        text: "TikTok User Statistics",
        font: {
          size: 16,
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    // onClick: (event, elements) => handleChartClick(event, elements),
  };
  return (
    <div className="container tiktok-user-statistics-container">
      <CommonHeader
        dashboardName={userDetails.nickname + " - TikTok User Statistics"}
      />
      <DaysFilter selectedDays={selectedDays} onChange={onChange} />

      <div className="metrix">
        <MetrixCards
          cards={[
            {
              icon: <SlUserFollowing size={40} color="#FF5722" />,
              title: "Follower Count",
              amount: userDetails?.follower_count,
              link: "",
            },
            {
              icon: <GiShadowFollower size={40} color="#9C27B0" />,
              title: "Following Count",
              amount: userDetails?.following_count,
              link: "",
            },
            {
              icon: <MdFavorite size={40} color="#4CAF50" />,
              title: "Total Favorited",
              amount: userDetails?.total_favorited,
              link: "",
            },
            {
              icon: <BiSolidVideos size={40} color="#673AB7" />,
              title: "Visible Video Count",
              amount: userDetails?.visible_videos_count,
              link: `/Tiktok-UsersDetails/${tiktokId}/videos`,
            },
          ]}
        />
      </div>

      <div
        style={{ backgroundColor: "#fff" }}
        className="d-flex rounded-2 align-items-center p-1 mt-4"
      >
        {returnBackButton()}
        {userDetails?.avatar && (
          <img
            src={userDetails?.avatar}
            alt="avatar"
            className="rounded-circle me-3"
            style={{
              width: "70px",
              height: "70px",
              objectFit: "cover",
            }}
          />
        )}
        {userDetails?.nickname && (
          <h4>
            <a
              href={userDetails?.tiktok_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {userDetails?.nickname}
            </a>
          </h4>
        )}
      </div>

      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : error ? (
        <div className="error-overlay">
          <p>{error}</p>
        </div>
      ) : (
        <div className="chart-wrapper">
          {userStatistics.length > 0 ? (
            <Line data={generateChartData()} options={options} />
          ) : (
            <p>No user statistics available.</p>
          )}
        </div>
      )}
      <div className="mt-5 mb-5">
        <h4 className="text-center">TikTok User Statistics</h4>
        <table className="user-table">
          <thead>
            <tr>
              <th>Followers</th>
              <th>Followings</th>
              <th>Total Likes</th>
              <th>Total Videos</th>
              <th>Recorded At</th>
            </tr>
          </thead>
          <tbody>
            {userStatistics.length > 0 ? (
              userStatistics?.map((user, index) => (
                <tr
                  className=""
                  key={user.id}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                  }}
                >
                  <td>{user.follower_count}</td>
                  <td>{user.following_count}</td>
                  <td>{user.total_favorited}</td>
                  <td>{user.visible_videos_count}</td>
                  <td>{new Date(user.recorded_at).toLocaleDateString()}</td>
                </tr>
              ))
            ) : (
              <p>No user statistics available.</p>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TikTokUserStatistics;
