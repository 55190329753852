import React, { useEffect, useState, useContext } from "react";
import { Line } from "react-chartjs-2";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { useParams } from "react-router-dom";
import DaysFilter from "./component/DaysFilter";
import moment from "moment";
import subDays from "date-fns/subDays";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { returnBackButton } from "../../../utils/helperfunctions";
import { LoginContext } from "../../../App";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TikTokSoundStatistics = () => {
  const { soundId } = useParams(),
    { selectedDays, setSelectedDays } = useContext(LoginContext),
    [userStatistics, setUserStatistics] = useState([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null);

  useEffect(() => {
    const fetchSoundStatistics = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      const from = moment(selectedDays[0]).format("YYYY-MM-DD");
      const to = moment(selectedDays[1]).format("YYYY-MM-DD");
      try {
        const response = await axiosInstance.post(
          "/api/tiktok/sounds/details",
          {
            sound_id: soundId,
            days: null,
            from,
            to,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserStatistics(response.data.data); // Updated to handle 'data' key from response
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch TikTok user statistics");
        setLoading(false);
      }
    };

    fetchSoundStatistics();
  }, [soundId, selectedDays]);

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  const chartData = {
    labels: userStatistics.map((stat) =>
      moment(stat.date).format("YYYY-MM-DD")
    ),
    datasets: [
      {
        label: "Total Users",
        data: userStatistics.map((stat) => stat.total_users),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0.3,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: true,
        text: "TikTok Sound Statistics",
        font: {
          size: 16,
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
    },
  };

  return (
    <div className="container tiktok-sound-statistics-container">
      <CommonHeader dashboardName={"TikTok Sound Statistics"} />
      {loading ? (
        <CommonLoader />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <DaysFilter selectedDays={selectedDays} onChange={onChange} />

          <div className="chart-container mt-4">
            <Line data={chartData} options={options} />
          </div>

          <div className="table-container mt-4">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Total Users</th>
                </tr>
              </thead>
              <tbody>
                {userStatistics.map((stat, index) => (
                  <tr key={index}>
                    <td>{moment(stat.date).format("YYYY-MM-DD")}</td>
                    <td>{stat.total_users}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default TikTokSoundStatistics;
