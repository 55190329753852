import React, { useEffect, useState, useContext } from "react";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link } from "react-router-dom";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import TikTokAccountFilters from "./component/TikTokAccountFilters";

const TikTokIndex = () => {
    const [accounts, setAccounts] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pagination, setPagination] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageStart, setPageStart] = useState(1);
    const [filters, setFilters] = useState({
        client_id: null,
        follower_count_from: "",
        follower_count_to: "",
        following_count_from: "",
        following_count_to: "",
        total_favorited_from: "",
        total_favorited_to: "",
        visible_videos_count_from: "",
        visible_videos_count_to: "",
        from: "",
        to: "",
    });
    const [clients, setClients] = useState([]);
    const [record, setRecord] = useState({
        visible: false,
        clients: [],
    });
    const fetchTikTok = async (page = 1) => {
        setLoading(true);
        const token = localStorage.getItem("token");
        try {
            const response = await axiosInstance.post(`/api/tiktok/accounts?page=${page}`, filters, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setAccounts(response.data.data);
            const { pagination: apiPagination } = response.data;
            setPagination(apiPagination);
            setCurrentPage(apiPagination.current_page);
            setTotalPages(apiPagination.last_page);
            setLoading(false);
        } catch (err) {
            setAccounts([]);
            setError("Failed to fetch TikTok Accounts");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTikTok(currentPage);
    }, [currentPage, filters]);

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page);
        const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
        setPageStart(newPageStart);
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
        setCurrentPage(1);
        fetchTikTok(1);
    };

    useEffect(() => {
        getClients();
    }, []);

    const getClients = async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axiosInstance.get(`/api/dashboard/clients`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                const localClients = response.data.clients?.map((client) => ({
                    name: client.name,
                    id: client.id,
                }));
                setRecord({
                    ...record,
                    visible: true,
                });
                setClients(localClients);
            }
        } catch (error) {
            console.error("Error fetching record for edit:", error);
        }
    };

    return (
        <div className="clientLists userLists">
            <CommonHeader
                dashboardName={"TikTok Account"}
            />
            {isLoading && (
                <div className="loader-overlay">
                    <CommonLoader />
                </div>
            )}
            <div className="mt-3">
                <div className="userFetchData">
                    <div className="userDetails">
                        <div
                            style={{ backgroundColor: "#fff" }}
                            className="d-flex align-content-center align-items-center"
                        >
                            <TikTokAccountFilters
                                clients={clients}
                                onFilterChange={handleFilterChange}
                            />
                        </div>
                        <div className="details mt-4">
                            <table className="user-table">
                                <thead>
                                    <tr>
                                        <th>Avatar</th>
                                        <th>Unique ID</th>
                                        <th>Nickname</th>
                                        <th>Client</th>
                                        <th>Follower Count</th>
                                        <th>Following Count</th>
                                        <th>Total Favorited</th>
                                        <th>Visible Videos</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                        <th>Profile Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accounts.length > 0 ? (
                                        accounts.map((account, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <img
                                                        src={account.avatar}
                                                        alt={account.nickname}
                                                        width="50"
                                                        height="50"
                                                        style={{ borderRadius: "50%" }}
                                                    />
                                                </td>
                                                <td>
                                                    <Link to={`/Tiktok-statistics/${account?.tiktok_id}`}>
                                                        {account.unique_id}
                                                    </Link>
                                                </td>
                                                <td>{account.nickname}</td>
                                                <td>{account.client}</td>
                                                <td>{account.follower_count}</td>
                                                <td>{account.following_count}</td>
                                                <td>{account.total_favorited}</td>
                                                <td>{account.visible_videos_count}</td>
                                                <td>{account.created_at}</td>
                                                <td>{account.updated_at}</td>
                                                <td>
                                                    <a href={account.url} target="_blank" rel="noopener noreferrer">
                                                        View Profile
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={11} className="text-center">
                                                No accounts found.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {accounts.length > 0 && totalPages > 1 && (
                            <div className="pagination">
                                <PaginationComponent
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    handlePageChange={handlePageChange}
                                    pageStart={pageStart}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TikTokIndex;