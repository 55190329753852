import React, { useState, createContext } from "react";
import "./App.css";
import DashboardIndex from "./components/Dashboard/DashboardIndex";
import Login from "./components/login-page/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter as Router } from "react-router-dom";
import subDays from "date-fns/subDays";
const LoginContext = createContext();

function App() {
  const [user, setUser] = useState({}),
    [createUser, setCreateUser] = useState(false),
    [selectedDays, setSelectedDays] = useState([
      subDays(new Date(), 30 - 1),
      new Date(),
    ]);

  function getComponent() {
    if (!user.isLoggedIn) {
      return <Login setUser={setUser} />;
    } else {
      return <DashboardIndex />;
    }
  }

  return (
    <>
      <LoginContext.Provider
        value={{
          user,
          setUser,
          createUser,
          setCreateUser,
          selectedDays,
          setSelectedDays,
        }}
      >
        <Router>{getComponent()}</Router>
      </LoginContext.Provider>
    </>
  );
}

export { App, LoginContext };
