import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CommonLoader from "../../../loader/CommonLoader";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../App";
import { hasPermission } from "../../../helperPermissionEditor";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import CustomModal from "../common-components/customModal";
import { LuEye } from "react-icons/lu";

const BookListing = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [books, setBooks] = useState([]),
    [isEditing, setIsEditing] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [clientOptions, setClientOption] = useState([]),
    [accountManagerOptions, setAccountManagerOptions] = useState([]),
    [soundOptions, setSoundOptions] = useState([]),
    [record, setRecord] = useState({
      sound: "",
      label_id: "",
    }),
    [view, setView] = useState({
      visible: false,
    }),
    [options, setOptions] = useState([]),
    [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRecord({
      title: "",
      type: "",
    });
    setIsEditing("");
    setView({});
  };
  const navigate = useNavigate();

  const getListing = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/books`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setBooks(response.data.books);
      setTotalPages(response.data.pagination.last_page);
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  useEffect(() => {
    getListing(currentPage);
    handleShow();

    const intervalId = setInterval(() => {
      getListing();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [currentPage]);

  const handleShow = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(`/api/books/create`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const clientDropDown = response?.data?.clients.map((client) => ({
        id: client.id,
        name: client.name,
      }));

      const accountManagerDropDown = response?.data?.account_manager.map(
        (account_manager) => ({
          id: account_manager.id,
          name: account_manager.name,
        })
      );

      const soundDropDown = response?.data?.sound.map((sd) => ({
        id: sd.id,
        name: sd.sound,
      }));

      setClientOption(clientDropDown);
      setAccountManagerOptions(accountManagerDropDown);
      setSoundOptions(soundDropDown);

      setOptions(response?.data?.label || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.message || "Error creating book");
      } else {
        console.error("Error creating book:", error);
        toast.error("Failed to create book. Please try again later.");
      }
    }
  };

  const handleEdit = async (row) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(`/api/books/${row.id}/edit`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);

      setShow(true);

      const { sound, label_id } = response.data.sound;

      setIsEditing(response.data.sound);

      setRecord((prevState) => ({
        ...prevState,
        sound,
        label_id,
      }));
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching Record for edit:", error);
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");

    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/books/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBooks(books.filter((sound) => sound.id !== id));

      toast.success("Record deleted successfully!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        navigate("/");
      } else {
        console.error("Error deleting Record:", error);
        toast.error("Error deleting Record");
      }
    }
    setIsLoading(false);
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    const updatedData = {
      book_name: record.bookName,
      client_id: record.clientId,
      genre: record.genre,
      status: record.status,
      account_manager_id: record.accountManagerId,
      hash_tag: record.hashTag,
      caption: record.caption,
      sound_id: record.soundId,
    };

    try {
      const response = await axiosInstance.put(
        `/api/books/${isEditing.id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleClose();
      toast.success("Record updated successfully");
      getListing();
      if (response.status === 200) {
        // Check for 200 status for a successful update
        setIsEditing("");
      } else {
        console.error("Error while updating:", response);
      }
    } catch (error) {
      console.error("Error while updating:", error);
      toast.error(error.response.data.message || "Please try again");
    }
  };

  const handleCreate = async () => {
    const {
      bookName,
      clientId,
      genre,
      accountManagerId,
      hashTag,
      caption,
      soundId,
    } = record;

    if (
      !bookName ||
      !clientId ||
      !genre ||
      !accountManagerId ||
      !hashTag ||
      !caption ||
      !soundId
    ) {
      toast.error("Please fill in all the fields before submitting.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      book_name: bookName,
      client_id: clientId,
      genre: genre,
      status: "1",
      account_manager_id: accountManagerId,
      hash_tag: hashTag,
      caption: caption,
      sound_id: soundId,
    };

    try {
      await axiosInstance.post(`/api/books`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      getListing();
      toast.success("Record created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error creating record");
      console.error("Error creating record:", error);
    }
  };
  const handleView = (record) => {
    setView({
      visible: true,
      ...record?.label,
    });
    setShow(true);
  };

  const getTableUI = () => {
    return (
      <div className="details mt-4">
        <table className="user-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Book Name</th>
              <th>Client</th>
              <th>Genre</th>
              <th>Account Manager</th>
              <th>Hash Tags</th>
              <th>Captions</th>
              <th>Sound</th>
              <th>Updated By</th>
              <th>Updated At</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {books.length > 0 ? (
              books.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.book_name}</td>
                  <td>{row.client}</td>
                  <td>{row.genre}</td>
                  <td>{row.account_manager}</td>
                  <td>{row.hash_tag}</td>
                  <td>{row.caption}</td>
                  <td>{row.sound}</td>
                  <td>{row.updated_by}</td>
                  <td>{row.updated_at}</td>
                  <td className="d-flex align-items-center justify-content-center">
                    <Toaster position="top-center" richColors />
                    <button
                      data-tooltip-id="view-button"
                      className="edit me-2"
                      onClick={() => handleView(row)}
                      style={{
                        padding: "4px 10px",
                        fontSize: "22px",
                      }}
                      disabled={!hasPermission(user?.permissions, "view-book")}
                    >
                      <LuEye />
                    </button>
                    <ReactTooltip
                      id="view-button"
                      place="bottom"
                      content="View Tiktok"
                    />

                    <span
                      className="px-2 edit me-2"
                      style={{ cursor: "pointer" }}
                    >
                      <TbEdit
                        size={24}
                        data-tooltip-id="editTooltip"
                        data-tooltip-content="Edit"
                        onClick={() => handleEdit(row)}
                        disabled={
                          !hasPermission(user?.permissions, "update-book")
                        }
                      />
                      <ReactTooltip id="editTooltip" />
                    </span>

                    <span
                      className="px-2"
                      style={{
                        cursor: "pointer",
                        background: "red",
                        color: "white",
                        fontSize: "16px",
                        padding: "8px 10px",
                        borderRadius: "8px",
                      }}
                    >
                      <MdDelete
                        size={24}
                        data-tooltip-id="deleteTooltip"
                        data-tooltip-content="Delete"
                        onClick={() => handleDelete(row.id)}
                        disabled={
                          !hasPermission(user?.permissions, "delete-book")
                        }
                      />
                      <ReactTooltip id="deleteTooltip" />
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No Records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const getFormUI = () => {
    return view?.visible ? (
      <table class="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {[view].map((record, i) => (
            <tr key={i}>
              <td>{record?.id}</td>
              <td>{record.title}</td>
              <td>{record.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div style={{ justifyContent: "center !important" }}>
        <Form.Group className="mb-3" controlId="formBookName">
          <Form.Control
            type="text"
            placeholder="Enter Book Name"
            value={record.bookName}
            onChange={(e) => setRecord({ ...record, bookName: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formClient">
          <Form.Select
            value={record.clientId}
            onChange={(e) =>
              setRecord({
                ...record,
                clientId: e.target.value,
              })
            }
          >
            <option>Client Options</option>
            {clientOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGenre">
          <Form.Control
            type="text"
            placeholder="Enter Genre"
            value={record.genre}
            onChange={(e) => setRecord({ ...record, genre: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formAccountManager">
          <Form.Select
            value={record.accountManagerId}
            onChange={(e) =>
              setRecord({
                ...record,
                accountManagerId: e.target.value,
              })
            }
          >
            <option>Account Manager Options</option>
            {accountManagerOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formHashTag">
          <Form.Control
            type="text"
            placeholder="Enter Hash Tag"
            value={record.hashTag}
            onChange={(e) => setRecord({ ...record, hashTag: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formCaption">
          <Form.Control
            type="text"
            placeholder="Enter Caption"
            value={record.caption}
            onChange={(e) => setRecord({ ...record, caption: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formSound">
          <Form.Select
            value={record.soundId}
            onChange={(e) =>
              setRecord({
                ...record,
                soundId: e.target.value,
              })
            }
          >
            <option>Sound Options</option>
            {soundOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Book"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => setShow(true)}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
          {totalPages > 1 && (
            <div className="pagination">
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                pageStart={pageStart}
              />
            </div>
          )}
        </div>
      </div>

      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <div>
            {view?.visible ? (
              <h2 className="create_user_heading"> Labels List </h2>
            ) : (
              <h2 className="create_user_heading">{`${isEditing ? "Update" : "Create"
                } Book`}</h2>
            )}
          </div>
        }
        bodyContent={getFormUI()}
        footerContent={
          <>
            <div className="d-flex justify-content-center align-items-center">
              {!view?.visible && (
                <Button
                  variant="primary"
                  className="creatrUserSubmit me-3"
                  onClick={isEditing ? handleUpdate : handleCreate}
                >
                  {`${isEditing ? "Update" : "Create"} Book`}
                </Button>
              )}
              <Button
                variant="secondary"
                className="creatrUserClose"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default BookListing;
