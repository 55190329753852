import React, { useEffect, useState, useContext } from "react";
import { Line } from "react-chartjs-2";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { useParams } from "react-router-dom";
import DaysFilter from "./component/DaysFilter";
import moment from "moment";
import subDays from "date-fns/subDays";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import DaysFilterOLD from "../common-components/DateFilterOld";
import { LoginContext } from "../../../App";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TikTokVideoStatistics = () => {
  const { selectedDays, setSelectedDays } = useContext(LoginContext),
    { videoId } = useParams(),
    [videoStatistics, setVideoStatistics] = useState([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null);

  const fetchVideoStatistics = async () => {
    setLoading(true);

    const token = localStorage.getItem("token");
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");

    try {
      const response = await axiosInstance.post(
        "/api/tiktok/video-statistics",
        { from, to, video_id: videoId, days: null },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setVideoStatistics(response.data.statistics);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch TikTok video statistics");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideoStatistics();
  }, [videoId, selectedDays]);

  const generateChartData = () => {
    const labels = videoStatistics.map((stat) =>
      new Date(stat.created_at).toLocaleDateString()
    );
    const data = {
      labels,
      datasets: [
        {
          label: "Views",
          data: videoStatistics.map((stat) => stat.views),
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: "#36A2EB",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
          yAxisID: "y",
        },
        {
          label: "Likes",
          data: videoStatistics.map((stat) => stat.likes),
          backgroundColor: "rgba(255, 206, 86, 0.2)",
          borderColor: "#FFCE56",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
          yAxisID: "y1",
        },
        {
          label: "Comments",
          data: videoStatistics.map((stat) => stat.comments),
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "#4BC0C0",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
          yAxisID: "y1",
        },
        {
          label: "Shares",
          data: videoStatistics.map((stat) => stat.shares),
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "#FF6384",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
          yAxisID: "y1",
        },
        {
          label: "Saved",
          data: videoStatistics.map((stat) => stat.saved),
          backgroundColor: "rgba(255, 87, 51, 0.2)",
          borderColor: "#FF5733",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
          yAxisID: "y1",
        },
      ],
    };
    return data;
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  return (
    <div className="container video-statistics-container">
      <CommonHeader dashboardName={videoId + " - Video Statistics"} />
      <DaysFilter selectedDays={selectedDays} onChange={onChange} />

      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : error ? (
        <div className="error-overlay">
          <p>{error}</p>
        </div>
      ) : (
        <div className="chart-wrapper">
          {videoStatistics.length > 0 ? (
            <Line
              data={generateChartData()}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                    labels: {
                      font: {
                        size: 16,
                      },
                    },
                  },
                  title: {
                    display: true,
                    text: "TikTok Video Statistics",
                    font: {
                      size: 16,
                    },
                  },
                },
                scales: {
                  y: {
                    type: "linear",
                    display: true,
                    position: "left",
                  },
                  y1: {
                    type: "linear",
                    display: true,
                    position: "right",
                    grid: {
                      drawOnChartArea: false,
                    },
                  },
                },
              }}
            />
          ) : (
            <p>No video statistics available.</p>
          )}
        </div>
      )}
      <div className="mt-5 mb-5">
        <p className="text-center">TikTok Video Statistics</p>
        {videoStatistics.length > 0 ? (
          <table className="user-table">
            <thead>
              <tr>
                <th>Views</th>
                <th>Likes</th>
                <th>Comments</th>
                <th>Shares</th>
                <th>Saved</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {videoStatistics.map((stat, index) => (
                <tr
                  className=""
                  key={stat.id}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                  }}
                >
                  <td>{stat.views}</td>
                  <td>{stat.likes}</td>
                  <td>{stat.comments}</td>
                  <td>{stat.shares}</td>
                  <td>{stat.saved}</td>
                  <td>{stat.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No video statistics available.</p>
        )}
      </div>
    </div>
  );
};

export default TikTokVideoStatistics;
