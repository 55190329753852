import React, { useState, useEffect, useCallback } from "react";
import { MultiSelect } from "react-multi-select-component";
import "bootstrap/dist/css/bootstrap.min.css";

const TikTokPostFilter = ({ tiktokUsers, activeTagsList, onFilterChange }) => {
  const initialFilters = {
    tiktok_id: "",
    views_from: "",
    views_to: "",
    likes_from: "",
    likes_to: "",
    comments_from: "",
    comments_to: "",
    shares_from: "",
    shares_to: "",
    saved_from: "",
    saved_to: "",
    tags: [],
    from: "",
    to: "",
  };

  const [filters, setFilters] = useState(initialFilters);

  // Debugging: Log initial render
  useEffect(() => {
    console.log("Initial Filters:", filters);
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [name]: value };
      console.log(`Filter Changed: ${name} = ${value}`, updatedFilters);
      return updatedFilters;
    });
  };

  const handleTagsFilter = (selectedTags) => {
    const tagValues = selectedTags.map((tag) => tag.value);
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, tags: tagValues };
      console.log("Tags Changed:", updatedFilters);
      return updatedFilters;
    });
  };

  const applyFilters = useCallback(() => {
    console.log("Applying Filters:", filters);
    onFilterChange(filters);
  }, [filters, onFilterChange]);

  const resetFilters = () => {
    setFilters(initialFilters);
    console.log("Filters Reset to:", initialFilters);
    onFilterChange(initialFilters);
  };

  // Reusable input function for numeric range
  const renderRangeInput = (label, nameFrom, nameTo, valueFrom, valueTo, keySuffix) => (
    <div className="col-md-6 mb-3" key={`${nameFrom}-${keySuffix}`}>
      <label>{label} (From - To)</label>
      <div className="d-flex">
        <input
          type="number"
          name={nameFrom}
          value={valueFrom}
          onChange={handleFilterChange}
          className="form-control me-2"
          placeholder="From"
        />
        <input
          type="number"
          name={nameTo}
          value={valueTo}
          onChange={handleFilterChange}
          className="form-control"
          placeholder="To"
        />
      </div>
    </div>
  );

  // Reusable input function for date range
  const renderDateInput = () => (
    <div className="col-md-6 mb-3" key="date-filter">
      <label>Date (From - To)</label>
      <div className="d-flex">
        <input
          type="date"
          name="from"
          value={filters.from}
          onChange={handleFilterChange}
          className="form-control me-2"
        />
        <input
          type="date"
          name="to"
          value={filters.to}
          onChange={handleFilterChange}
          className="form-control"
        />
      </div>
    </div>
  );

  return (
    <div className="filter-container p-3">
      <div className="row">
        {/* TikTok User Filter */}
        <div className="col-md-6 mb-3" key="tiktok-user-filter">
          <label htmlFor="tiktokUser">Filter by TikTok User:</label>
          <select
            className="form-select"
            id="tiktokUser"
            name="tiktok_id"
            value={filters.tiktok_id}
            onChange={handleFilterChange}
          >
            <option value="">All Accounts</option>
            {tiktokUsers.map((user) => (
              <option key={user.tiktok_id} value={user.tiktok_id}>
                {user.tiktok_nickname}
              </option>
            ))}
          </select>
        </div>

        {/* Views Filter */}
        {renderRangeInput("Views", "views_from", "views_to", filters.views_from, filters.views_to, "views")}

        {/* Likes Filter */}
        {renderRangeInput("Likes", "likes_from", "likes_to", filters.likes_from, filters.likes_to, "likes")}

        {/* Comments Filter */}
        {renderRangeInput("Comments", "comments_from", "comments_to", filters.comments_from, filters.comments_to, "comments")}

        {/* Shares Filter */}
        {renderRangeInput("Shares", "shares_from", "shares_to", filters.shares_from, filters.shares_to, "shares")}

        {/* Saved Filter */}
        {renderRangeInput("Saved", "saved_from", "saved_to", filters.saved_from, filters.saved_to, "saved")}

        {/* Date Filter */}
        {renderDateInput()}

        {/* Tags Filter */}
        <div className="col-md-12 mb-3" key="tags-filter">
          <label>Filter by Tags:</label>
          <MultiSelect
            options={activeTagsList}
            value={activeTagsList.filter((tag) => filters.tags.includes(tag.value))}
            onChange={handleTagsFilter}
            labelledBy="Select"
            className="multi-select-container"
          />
        </div>
      </div>

      {/* Buttons */}
      <div className="row mt-3">
        <div className="col-md-12">
          <button className="btn btn-primary me-2" onClick={applyFilters}>
            Apply Filters
          </button>
          <button className="btn btn-secondary" onClick={resetFilters}>
            Reset Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default TikTokPostFilter;
