import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useParams } from "react-router-dom";
import CommonLoader from "../../../loader/CommonLoader";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../App";

const DailyAPIHits = () => {
  const { user } = useContext(LoginContext),
    [data, setData] = useState([]),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    location = useLocation(),
    state = location.state,
    { id } = useParams();

  const getListing = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(
        `/api/tiktok/daily-api-hits`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const apiData = response.data.data;

      setData(apiData);
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getListing();

    const intervalId = setInterval(() => {
      getListing();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const getTableUI = () => {
    return (
      <div className="details mt-4">
        <table className="user-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Endpoint</th>
              <th>Date</th>
              <th>Totls Hits</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data.map((row, i) => (
                <tr key={row.id}>
                  <td>{i + 1}</td>
                  <td>{row.endpoint}</td>
                  <td>{row.date}</td>
                  <td>{row.total_hits}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No Records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Daily Hits by API's"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => {}}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
        </div>
      </div>

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default DailyAPIHits;
