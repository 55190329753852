import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import SkeletonLoader from "../../common-components/skeletonLoader";
import ErrorPage from "../../common-components/error";
import subDays from "date-fns/subDays";
import DaysFilter from "./DaysFilter";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LimitFilter from "./LimitFilter";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TopTrendingVideos = () => {
  const [videoData, setVideoData] = useState([]),
    [selectedDays, setSelectedDays] = useState([
      subDays(new Date(), 30 - 1),
      new Date(),
    ]),
    [selectedLimit, setSelectedLimit] = useState(10),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null);

  const fetchTopVideos = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");

    try {
      const response = await axiosInstance.post(
        "/api/dashboard/top-trending-videos",
        { days: null, from, to, limit: selectedLimit },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setVideoData(response.data.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch top trending videos.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopVideos();
  }, [selectedDays, selectedLimit]);

  const generateChartData = () => {
    const labels = videoData.map((video) => video.video_id);
    const data = {
      labels,
      datasets: [
        {
          label: "Views",
          data: videoData.map((video) => video.views),
          backgroundColor: "#FF6384",
          borderColor: "#FF6384",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "#FF6384",
        },
        {
          label: "Interactions",
          data: videoData.map((video) => video.interactions),
          backgroundColor: "#9966FF",
          borderColor: "#9966FF",
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "#9966FF",
        },
      ],
    };
    return data;
  };

  const handleChartClick = (event, elements) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      const videoId = videoData[index].video_id;
      window.location.href = `/Tiktok-video-statistics/${videoId}`;
    }
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };
  const onChangeLimit = (value) => {
    setSelectedLimit(value);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
    onClick: (event, elements) => handleChartClick(event, elements),
  };

  return (
    <div>
      <div className="filter-container p-3">
        <div class="row">
          <div class="col-md-6"><DaysFilter selectedDays={selectedDays} onChange={onChange} /></div>
          <div class="col-md-6 limit-filter"><LimitFilter selectedLimit={selectedLimit} onChange={onChangeLimit} /></div>
        </div>
      </div>

      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : error ? (
        <ErrorPage refetch={fetchTopVideos} />
      ) : (
        <>
          <h5 className="chart-title">
            Top Trending Videos &nbsp;
            <span data-tooltip-id="help-tooltip" style={{ fontSize: "26px" }}>
              <IoIosHelpCircleOutline />
            </span>
            <ReactTooltip
              id="help-tooltip"
              place="bottom"
              content="Helping Text"
            />
          </h5>
          <Bar data={generateChartData()} options={options} />
        </>
      )}
    </div>
  );
};

export default TopTrendingVideos;
