import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import SkeletonLoader from "../../common-components/skeletonLoader";
import ErrorPage from "../../common-components/error";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import YearFilter from "./YearFilter";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TotalVideosMetrix = () => {
  const [selectedYear, setYear] = useState(2024);
  const [totalOrdersData, setTotalOrdersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTotalOrders = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        "/api/tiktok/total-amount-of-videos",
        {
          year: selectedYear,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalOrdersData(response.data.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch total orders data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTotalOrders();
  }, [selectedYear]);

  const generateChartData = () => {
    const labels = totalOrdersData.map((item) => `${item.month}-${item.year}`);
    const data = {
      labels,
      datasets: [
        {
          label: "Total Orders (Videos Uploaded)",
          data: totalOrdersData.map((item) => item.total),
          backgroundColor: "#36A2EB",
          borderColor: "#36A2EB",
          borderWidth: 1,
        },
      ],
    };
    return data;
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.raw} videos`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month-Year",
        },
      },
      y: {
        title: {
          display: true,
          text: "Total Videos",
        },
        beginAtZero: true,
      },
    },
  };
  const yearChange = (year) => {
    setYear(year);
  };

  return (
    <div className="total-orders-bar-graph">
      <YearFilter selectedYear={selectedYear} onChange={yearChange} />
      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : error ? (
        <ErrorPage refetch={fetchTotalOrders} />
      ) : (
        <div className="chart-wrapper">
          {totalOrdersData.length > 0 ? (
            <>
              <h5 className="chart-title">
                Total Videos Uploaded per Month &nbsp;
                <span
                  data-tooltip-id="help-tooltip"
                  style={{ fontSize: "26px" }}
                >
                  <IoIosHelpCircleOutline />
                </span>
                <ReactTooltip
                  id="help-tooltip"
                  place="bottom"
                  content="Helping Text"
                />
              </h5>
              <Bar data={generateChartData()} options={options} />
            </>
          ) : (
            <p>No data available for the selected year.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TotalVideosMetrix;
