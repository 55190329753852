import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import DaysFilter from "./DaysFilter";
import SkeletonLoader from "../../common-components/skeletonLoader";
import ErrorPage from "../../common-components/error";
import moment from "moment";
import subDays from "date-fns/subDays";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TikTokFollowerGrowth = () => {
  const [selectedDays, setSelectedDays] = useState([
    subDays(new Date(), 30 - 1),
    new Date(),
  ]);
  const [followerGrowthData, setFollowerGrowthData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchFollowerGrowth = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");
    try {
      const response = await axiosInstance.post(
        "/api/dashboard/follower-growth",
        {
          days: null,
          from,
          to,
          limit: 30,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFollowerGrowthData(response.data.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch TikTok follower growth data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFollowerGrowth();
  }, [selectedDays]);

  const generateChartData = () => {
    const labels = followerGrowthData.map((item) => item.date);
    const data = {
      labels,
      datasets: [
        {
          label: "Total Followers",
          data: followerGrowthData.map((item) => item.total_followers),
          borderColor: "#36A2EB",
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          fill: true,
        },
      ],
    };
    return data;
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  if (loading) return <SkeletonLoader />;
  if (error) return <ErrorPage refetch={fetchFollowerGrowth} />;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
  };
  return (
    <div className="follower-growth-container">
      <DaysFilter selectedDays={selectedDays} onChange={onChange} />
      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : (
        <div className="chart-wrapper">
          {followerGrowthData.length > 0 ? (
            <>
              <h5 className="chart-title">
                TikTok Follower Growth &nbsp;
                <span
                  data-tooltip-id="help-tooltip"
                  style={{ fontSize: "26px" }}
                >
                  <IoIosHelpCircleOutline />
                </span>
                <ReactTooltip
                  id="help-tooltip"
                  place="bottom"
                  content="Helping Text"
                />
              </h5>
              <Line data={generateChartData()} options={options} />
            </>
          ) : (
            <p>No follower growth data available.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TikTokFollowerGrowth;
